import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'; 
import Home from './pages/Home';
import AdminMain from './adminPanel/AdminMain';

const router = createBrowserRouter([
 
  {
    path:"/",
    element: <App />,
    children:[
      {
        path:"/",
        element: <Home />
      },
      // {
      //   path: '/',
      //   element: < />,
      //   noindex: false,
      // },
      // {
      //   path: '/',
      //   element: < />
      // }
    ]
  },
  {
    path:"/airdrop",
    element:<AdminMain/>, 
  },
  
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <RouterProvider router={router}/>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
